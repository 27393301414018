import Hls from 'hls.js';
import PlyrJS from 'plyr';
import { PlyrOptions, PlyrSource } from 'plyr-react';
import React, { HTMLAttributes, MutableRefObject, useEffect, useRef } from 'react';
import '../../styles/plyr.less';
import './video_player.less';

export type PlyrProps = HTMLAttributes<HTMLVideoElement> & {
  source?: PlyrSource;
  muxPlaybackId?: string;
  options?: PlyrOptions;
};
export type HTMLPlyrVideoElement = HTMLVideoElement & { plyr?: PlyrJS };

// lots of muted code here trying to get autoplay to work in ios
export const VideoPlayer = React.forwardRef<HTMLPlyrVideoElement, PlyrProps>((props, ref) => {
  const { options = null, muxPlaybackId, source, ...rest } = props;

  const isMuted = useRef(!!options?.muted);

  const innerRef = useRef<HTMLPlyrVideoElement>();
  useEffect(() => {
    const video = innerRef.current;
    if (!video) {
      return;
    }

    if (!video?.plyr) {
      video.plyr = new PlyrJS(video, options ?? {});

      if (options.muted) {
        video.plyr.muted = true;
        video.muted = true;
      }

      if (muxPlaybackId) {
        const muxSrc = `https://stream.mux.com/${muxPlaybackId}.m3u8`;
        video.plyr.poster =
          source?.poster ?? `https://image.mux.com/${muxPlaybackId}/thumbnail.jpg`;

        if (video.canPlayType('application/vnd.apple.mpegurl')) {
          video.src = muxSrc;
        } else if (Hls.isSupported()) {
          const hls = new Hls();
          hls.loadSource(muxSrc);
          hls.attachMedia(video);
        }
      } else {
        video.plyr.poster = source?.poster ?? null;
        video.plyr.source = source;
      }
    }

    if (typeof ref === 'function') {
      if (video) {
        ref(video);
      }
    } else {
      if (ref && video) {
        ref.current = video;
      }
    }
  }, [ref, options, muxPlaybackId, innerRef.current]);

  return (
    <video
      ref={innerRef as unknown as MutableRefObject<HTMLVideoElement>}
      className="plyr-react plyr"
      playsInline
      autoPlay={options?.autoplay}
      muted={isMuted.current}
      {...rest}
    />
  );
});

VideoPlayer.displayName = 'VideoPlayer';

VideoPlayer.defaultProps = {
  options: {
    controls: [
      'rewind',
      'play',
      'play-large',
      'fast-forward',
      'progress',
      'current-time',
      'duration',
      'mute',
      'volume',
      'settings',
      'fullscreen',
    ],
    i18n: {
      restart: 'Restart',
      rewind: 'Rewind {seektime}s',
      play: 'Play',
      pause: 'Pause',
      fastForward: 'Forward {seektime}s',
      seek: 'Seek',
      seekLabel: '{currentTime} of {duration}',
      played: 'Played',
      buffered: 'Buffered',
      currentTime: 'Current time',
      duration: 'Duration',
      volume: 'Volume',
      mute: 'Mute',
      unmute: 'Unmute',
      enableCaptions: 'Enable captions',
      disableCaptions: 'Disable captions',
      download: 'Download',
      enterFullscreen: 'Enter fullscreen',
      exitFullscreen: 'Exit fullscreen',
      frameTitle: 'Player for {title}',
      captions: 'Captions',
      settings: 'Settings',
      menuBack: 'Go back to previous menu',
      speed: 'Speed',
      normal: 'Normal',
      quality: 'Quality',
      loop: 'Loop',
    },
  },
};

export default VideoPlayer;
